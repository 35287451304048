<template>
  <v-container
    class="fill-height"
    fluid
  >
    <v-row
      align="center"
      justify="center"
    >
      <v-col
        cols="12"
        sm="8"
        md="4"
      >
        <v-tabs
          v-model="tab"
          centered
        >
          <v-tab key="login">
            Login
          </v-tab>
          <v-tab key="signup">
            Signup
          </v-tab>
        </v-tabs>
        <v-tabs-items
          v-model="tab"
          continuous
        >
          <v-tab-item key="login">
            <LoginCard @validate="login" />
          </v-tab-item>
          <v-tab-item key="signup">
            <SignupCard @validate="signup" />
          </v-tab-item>
        </v-tabs-items>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapActions } from 'vuex'
import LoginCard from '@/pages/Account/__forms__/Login'
import SignupCard from '@/pages/Account/__forms__/Signup'

export default {
  name: 'Account',
  layout: 'empty',
  components: { LoginCard, SignupCard },
  data () {
    return {
      tab: null
    }
  },
  methods: {
    ...mapActions('auth', ['login', 'signup'])
  }
}
</script>
