<template>
  <v-card flat>
    <v-card-text>
      <v-form v-model="form">
        <v-text-field
          v-model="fields.email"
          label="Email"
          name="Email"
          :rules="[rules.email, rules.required]"
          prepend-icon="mdi-at"
          type="text"
          autocomplete="username"
        />
        <v-text-field
          v-model="fields.password"
          label="Password"
          name="password"
          :rules="[rules.required]"
          prepend-icon="mdi-lock"
          type="password"
          autocomplete="current-password"
        />
      </v-form>
    </v-card-text>
    <v-card-actions class="justify-center">
      <v-btn
        :disabled="!form"
        color="primary"
        @click="$emit('validate', fields)"
      >
        Login
      </v-btn>
    </v-card-actions>
  </v-card>
</template>
<script>
import formMixin from '@/mixins/formMixin'

export default {
  name: 'LoginCard',
  mixins: [formMixin]
}
</script>
