<template>
  <v-card flat>
    <v-card-text>
      <v-form v-model="form">
        <v-text-field
          v-model="fields.firstName"
          label="First Name"
          :rules="[rules.required]"
          prepend-icon="mdi-account"
        />
        <v-text-field
          v-model="fields.lastName"
          label="Last Name"
          :rules="[rules.required]"
          prepend-icon="mdi-account-outline"
        />
        <vue-tel-input-vuetify
          label="Cellphone Number"
          placeholder=""
          :rules="[rules.required]"
          @input="updateCellphone"
        />
        <v-text-field
          v-model="fields.email"
          label="Email"
          :rules="[rules.email, rules.required]"
          prepend-icon="mdi-at"
          type="text"
          autocomplete="username"
        />
        <v-text-field
          v-model="fields.password"
          label="Password"
          :rules="[rules.required]"
          prepend-icon="mdi-lock"
          type="password"
          autocomplete="new-password"
        />
        <v-text-field
          v-model="passwordConfirm"
          label="Password Confirmation"
          :rules="[rules.required, rules.isExactString(fields.password),]"
          prepend-icon="mdi-lock-outline"
          type="password"
          autocomplete="false"
        />
      </v-form>
    </v-card-text>
    <v-card-actions class="justify-center">
      <v-btn
        :disabled="!form"
        color="primary"
        @click="$emit('validate',fields)"
      >
        Signup
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>

import formMixin from '@/mixins/formMixin'

export default {
  name: 'SignupCard',
  mixins: [formMixin],
  data () {
    return {
      passwordConfirm: ''
    }
  },
  methods: {
    updateCellphone (str, obj) {
      this.fields.cellphoneNumber = str
      this.fields.cellphoneCc = '+' + obj.country.dialCode
    }
  }
}
</script>
